<template>
  <div class="p-4 counseling">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="모노를 알게 된 경로"
          class="mt-5 mb-5"
          :message="errors"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
        >
          <div class="block">
            <b-radio v-model="form.path" native-value="인스타그램">인스타그램</b-radio>
            <b-radio v-model="form.path" native-value="홈페이지">홈페이지</b-radio>
            <b-radio v-model="form.path" native-value="블로그">블로그</b-radio>
            <b-radio v-model="form.path" native-value="소개">소개</b-radio>
            <b-radio v-model="form.path" native-value="기타">기타</b-radio>
          </div>
        </b-field>
      </validation-provider>
      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="성함"
          class="mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input v-model="form.name" placeholder="성함을 입력해주세요"></b-input>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="연락처"
          class="mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            required
            validation-message="연락처를 입력해주세요"
            v-model="form.tel"
            placeholder="연락처를 입력해주세요"
          ></b-input>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="지역"
          class="m-0"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <div class="flex gap-2 mb-2">
            <b-button class="flex-1" size="small" @click="dialogVisible = true">주소 검색</b-button>
            <b-input
              custom-class="address-input"
              style="flex: 6 1 0"
              placeholder="주소 입력"
              disabled
              v-model="form.address"
            ></b-input>
            <b-input
              custom-class="address-input"
              style="flex: 3 1 0"
              placeholder="우편번호"
              disabled
              v-model="form.postcode"
            ></b-input>
          </div>
        </b-field>
        <b-input
          placeholder="상세 주소 입력"
          :disabled="detailAddressDisabled"
          v-model="form.detailAddress"
          class="mb-5"
        ></b-input>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="건물 유형"
          class="mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <div class="block">
            <b-radio v-model="form.buildType" native-value="상가">상가</b-radio>
            <b-radio v-model="form.buildType" native-value="주택">주택</b-radio>
            <b-radio v-model="form.buildType" native-value="아파트">아파트</b-radio>
            <b-radio v-model="form.buildType" native-value="주상복합">주상복합</b-radio>
            <b-radio v-model="form.buildType" native-value="오피스텔">오피스텔</b-radio>
          </div>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="사용 용도(업종)"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <div class="block">
            <b-radio v-model="form.usecase" native-value="주거">주거</b-radio>
            <b-radio v-model="form.usecase" native-value="의류매장">의류매장</b-radio>
            <b-radio v-model="form.usecase" native-value="사무실">사무실</b-radio>
            <b-radio v-model="form.usecase" native-value="스튜디오">스튜디오</b-radio>
            <b-radio v-model="form.usecase" native-value="병원">병원</b-radio>
            <b-radio v-model="form.usecase" native-value="학원">학원</b-radio>
            <b-radio v-model="form.usecase" native-value="카페">카페</b-radio>
            <b-radio v-model="form.usecase" native-value="레스토랑">레스토랑</b-radio>
            <b-radio v-model="form.usecase" native-value="기타">기타(직접 입력)</b-radio>
          </div>
        </b-field>
      </validation-provider>

      <validation-provider
        v-if="form.usecase === '기타'"
        rules="required"
        v-slot="{ errors, valid }"
      >
        <b-field
          class="mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            required
            validation-message="사용 용도를 입력해주세요"
            v-model="specialUseCase"
            placeholder="사용 용도를 입력해주세요"
          ></b-input>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="면적"
          class="mt-5 mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            required
            validation-message="면적을 입력해주세요"
            v-model="form.area"
            placeholder="면적을 입력해주세요"
          ></b-input>
          <b-select v-model="unit">
            <option value="평">평</option>
            <option value="m^2">제곱미터</option>
          </b-select>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="공사 범위"
          class="mt-5 mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <div class="block">
            <b-radio v-model="form.range" native-value="부분 인테리어">부분 인테리어</b-radio>
            <b-radio v-model="form.range" native-value="전체 인테리어">전체 인테리어</b-radio>
            <b-radio v-model="form.range" native-value="건축대수선">건축대수선</b-radio>
          </div>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="공사희망일"
          class="mt-5 mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-datepicker
            v-model="form.date"
            :show-week-number="false"
            placeholder="공사희망일을 선택해주세요."
            icon="calendar-day"
            :icon-right="form.date ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            trap-focus
          ></b-datepicker>
        </b-field>
      </validation-provider>

      <validation-provider rules="required" v-slot="{ errors, valid }">
        <b-field
          label="예산"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <div class="block">
            <b-radio v-model="form.budget" native-value="2000">1000~2000</b-radio>
            <b-radio v-model="form.budget" native-value="3000">2000~3000</b-radio>
            <b-radio v-model="form.budget" native-value="4000">3000~4000</b-radio>
            <b-radio v-model="form.budget" native-value="5000">4000~5000</b-radio>
            <b-radio v-model="form.budget" native-value="6000">5000~6000</b-radio>
            <b-radio v-model="form.budget" native-value="7000">6000~7000</b-radio>
            <b-radio v-model="form.budget" native-value="8000">7000~8000</b-radio>
            <b-radio v-model="form.budget" native-value="9000">8000~9000</b-radio>
            <b-radio v-model="form.budget" native-value="10000">1억 이상(직접 입력)</b-radio>
          </div>
        </b-field>
      </validation-provider>

      <validation-provider
        v-if="form.budget === '10000'"
        rules="required"
        v-slot="{ errors, valid }"
      >
        <b-field
          class="mb-5"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            required
            validation-message="예산을 입력해주세요"
            v-model="specialBudget"
            placeholder="예산을 입력해주세요"
          ></b-input>
        </b-field>
      </validation-provider>

      <b-field class="mt-5">
        <b-button type="primary" @click="handleSubmit(onSubmit)">전송</b-button>
      </b-field>
    </validation-observer>

    <b-modal v-model="dialogVisible" :width="$isMobile() ? '100%' : '50%'">
      <vue-daum-postcode @complete="onComplete" />
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import client from '../api/client';

export default defineComponent({
  name: 'Counseling',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const dialogVisible = ref(false);
    const detailAddressDisabled = computed(() => !form.address);
    extend('required', {
      ...required,
      message: '필수 입력 항목입니다.',
    });

    const form = reactive({
      path: '',
      name: '',
      tel: '',
      address: '',
      postcode: '',
      detailAddress: '',
      buildType: '',
      usecase: '',
      area: '',
      range: '',
      date: null,
      budget: '',
    });
    const specialUseCase = ref('');
    const specialBudget = ref('');
    const unit = ref('평');

    function onComplete(result) {
      form.address = result.address;
      form.postcode = result.zonecode;
      dialogVisible.value = false;
    }

    const observer = ref(null);

    async function onSubmit() {
      try {
        const usecase = form.usecase === '기타' ? specialUseCase.value : form.usecase;
        const budget = form.budget === '10000' ? specialBudget.value : form.budget;

        await client.sendCounseling(
          form.path,
          form.name,
          form.tel,
          form.address,
          form.detailAddress,
          form.postcode,
          form.buildType,
          usecase,
          `${form.area}${unit.value}`,
          form.range,
          form.date,
          budget
        );
      } catch (e) {
        Dialog.alert('에러가 발생했습니다. 관리자에 문의해주세요.');
        return;
      }

      Dialog.alert('성공적으로 전송되었습니다.');

      form.path = '';
      form.name = '';
      form.tel = '';
      form.address = '';
      form.postcode = '';
      form.detailAddress = '';
      form.buildType = '';
      form.usecase = '';
      form.area = '';
      form.range = '';
      form.date = null;
      form.budget = '';
      specialUseCase.value = '';
      specialBudget.value = '';
      unit.value = '평';
    }

    function clearDate() {
      form.date = null;
    }

    return {
      form,
      dialogVisible,
      detailAddressDisabled,
      unit,

      onComplete,
      onSubmit,

      specialUseCase,
      specialBudget,

      clearDate,
      required,
      observer,
    };
  },
});
</script>

<style lang="scss">
.el-form-item__label {
  font-weight: 700;
}

.b-radio.radio:not(.button) {
  margin-right: 1em;
}

.counseling input {
  font-weight: bold;
}

.counseling .help {
  color: #f14668;
}

.counseling .block {
  margin-bottom: 0;
}

.counseling .address-input {
  background-color: white;
  color: #363636;
}
</style>
